export default class DynamicNavigation {
  constructor() {
    this.navToggle = document.getElementById('navToggle');

    // Secondary Navigation Declarations
    this.secondaryNav = document.getElementById('secondary');

    // Mobile Navigation Declarations
    this.mobileNav = document.getElementById('mobile-navigation');
    this.mobileMenu = document.getElementById('mobile-main');
    this.mobileMenuContainer = document.querySelector('.menu-mobile-main-container');
    this.levelOneItems = document.querySelectorAll('#mobile-main > .menu-item');
    this.mobileExpansionArrows = [];
    this.createMenuExpanders();

    // Functional Declarations
    this.header = document.querySelector('.main-header');
    this.menuOpen = false;

    this.init();
    this.events();
  }

  init() {}

  events() {
    this.navToggle.addEventListener('click', () => {
      if (!this.menuOpen) {
        this.openMenu(this.mobileNav);
      } else {
        this.closeMenus();
      }
    });

    window.addEventListener('resize', this.closeMenus.bind(this));
  }

  closeMenus() {
    // this.closeMenu(this.secondaryNav);
    this.closeMenu(this.mobileNav);
  }

  createMenuExpanders() {
    // Create expansion and close buttons for mobile menu items with children
    this.levelOneItems.forEach((item) => {
      if (item.classList.contains('menu-item-has-children')) {
        const menuExpandButton = document.createElement('div');
        const expandIcon = document.createElement('i');
        const itemSubMenu = item.querySelector('.sub-menu');

        menuExpandButton.classList.add('menu-expand');
        expandIcon.classList.add('fal');
        expandIcon.classList.add('fa-arrow-right');
        menuExpandButton.appendChild(expandIcon);
        item.querySelector('a').appendChild(menuExpandButton);

        menuExpandButton.addEventListener('click', (e) => {
          e.preventDefault();
          this.mobileMenu.style.transform = 'translateX(-100vw)';
          itemSubMenu.style.transform = 'translateX(100vw)';
          this.mobileMenuContainer.scrollTop = 0;
          itemSubMenu.style.display = 'flex';
        });

        const menuCloseButton = document.createElement('div');
        const closeIcon = document.createElement('i');
        menuCloseButton.classList.add('menu-close');
        closeIcon.classList.add('fal');
        closeIcon.classList.add('fa-arrow-left');
        menuCloseButton.appendChild(closeIcon);
        itemSubMenu.insertBefore(menuCloseButton, itemSubMenu.firstChild);

        menuCloseButton.addEventListener('click', (e) => {
          e.preventDefault();
          this.mobileMenu.style.transform = 'translateX(0)';
          itemSubMenu.style.transform = 'translateX(100vw)';
          this.mobileMenuContainer.scrollTop = 0;

          setTimeout(() => {
            itemSubMenu.style.display = 'none';
          }, 400);
        });
      }
    });
  }

  // Returns true if screen width is greater than 1440px
  isDesktop() {
    return window.innerWidth >= 1440 ? true : false;
  }

  closeMenu(menu) {
    this.navToggle.classList.remove('active');
    menu.classList.remove('active');
    this.menuOpen = false;

    if (menu === this.mobileNav) {
      document.body.style.overflow = 'auto';

      if (window.scrollY < 150) {
        this.header.classList.remove('scrolled');
      }
    }
  }

  openMenu(menu) {
    this.navToggle.classList.add('active');
    menu.classList.add('active');
    this.menuOpen = true;

    if (menu === this.mobileNav) {
      document.body.style.overflow = 'hidden';
      this.header.classList.add('scrolled');
    }
  }
}
