import SlideShow, { createSlideShows } from './components/hero-slides';
import { slider } from './components/slider';
import { menu } from './components/menus';
import Accordion from './components/accordion';
import Form from './components/forms';
import NewsSlideShow from './components/news-slides';
import DynamicNavigation from './components/dynamic-navigation';
import WOW from './vendors/wow.min';
import Modal from './components/modal';

/*
		========================
		 Add some classes for IE bug fixing
		 From https://www.tenpixelsleft.com/target-ie10-or-ie11-using-javascript/
		========================
		*/
if (!!window.MSInputMethodContext && !!document.documentMode) {
  $('html').addClass('ie ie11');
}

// Set header Height CSS variable

const headerHeight = () => {
  const el = document.getElementById('header');

  // set header height CSS variable
  document.documentElement.style.setProperty('--header-height', el.offsetHeight + 'px');
};

window.addEventListener('load', (event) => {
  headerHeight();

  // Check for touch screen
  function isTouchDevice() {
    return 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  }

  if (isTouchDevice()) {
    document.body.classList.add('touch-true');
  } else {
    document.body.classList.add('touch-false');
  }

  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  objectFitImages();
  menu();
  createSlideShows();
  slider();
  new Accordion();
  new Form();
  new NewsSlideShow();
  new DynamicNavigation();
  new Modal();
});

document.addEventListener('DOMContentLoaded', () => {
  const wow = new WOW({
    boxClass: 'wow', // default
    animateClass: 'animated', // default
    offset: 0, // default
    mobile: true, // default
    live: true, // default
  });

  wow.init();
});
