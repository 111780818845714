"use strict";

export default class SlideShow {
    // 1. describe and create/initiate our object
    constructor(el) {
        this.slides = el.querySelectorAll('.slides__slide');
        this.controls = document.querySelector('.slides__controls');
        this.selectors;
        this.slideNum = 0;
        this.timer;

        if(this.slides.length){
            this.init();
        }
    }
  
    // 2. events
    events() {
        this.selectors.forEach( slideSelector =>{
            slideSelector.addEventListener('click', ()=>{
                this.slideNum = slideSelector.dataset.slide;
                this.switchSlide();
                clearInterval(this.timer);
                this.timer = setInterval(this.switchSlide.bind(this), 4000);
            });
        })
    }
  
    // 3. methods (function, action...)

    init() {
        if(this.slides.length > 1) {
            this.slides.forEach((slide)=>{
                const slideSelector = document.createElement("I");
                slideSelector.className = "far fa-circle slide-selector";
                slideSelector.dataset.slide = slide.dataset.slide;
                this.controls.appendChild(slideSelector);
            })
            this.selectors = document.querySelectorAll('.slide-selector');
            this.switchSlide();
            this.timer = setInterval(this.switchSlide.bind(this), 4000);
            this.events();
        }
    }



    switchSlide() {        
        this.slides.forEach((slide)=>{
            slide.classList.remove('current-slide');
            if(this.slideNum == slide.dataset.slide) {
                slide.classList.add('current-slide'); 
            }
        })

        this.buttonSwitcher();

        if(this.slideNum < this.slides.length - 1) {
            this.slideNum ++
        } else {
            this.slideNum = 0;
        }
    }

    buttonSwitcher() {
        this.selectors.forEach( slideSelector =>{
            slideSelector.classList.remove('current-button');

            if(this.slideNum == slideSelector.dataset.slide) {
                slideSelector.classList.add('current-button');
            }
        })
    }
}

export const createSlideShows = () => {
    const slideshows = document.querySelectorAll('.slides');

    slideshows.forEach(slideshow => {
        new SlideShow(slideshow);
    });
}



