export default class Accordion {
    constructor () {
        this.allAccordions = document.querySelectorAll("#accordion");
        this.currentItems;
        this.currentHeading;
        this.currentText;

        if(this.allAccordions.length){
            this.init();
        }
    }

    init(){
        // Loop through all accordions on the page
        this.allAccordions.forEach(accordion => {
            this.currentItems = accordion.querySelectorAll('.accordion__item');

            // Loop through all items in the accordion
            this.currentItems.forEach(item => {
                let heading = item.querySelector(".accordion__heading");
                let text = item.querySelector(".accordion__text");
                heading.addEventListener('click', () => this.expand(this.currentItems, item, text))
            })
        })
    }

    expand(currentItems, item, text){

        // If Item is closed, open it and close all others
        // If Item is open, close it
        if(item.classList.contains('closed')) {
            this.closeAll(currentItems);
            this.openItem(item, text);
        } else {
            this.closeItem(item, text)
        }
    }

    closeAll(currentItems){
        currentItems.forEach(item => {
            let text = item.querySelector(".accordion__text");
            this.closeItem(item, text);
        })
    }

    openItem(item, text) {
        item.classList.remove('closed');
        item.classList.add('open');
        text.style.paddingTop = '15px';
        text.style.paddingBottom = '15px';
        text.style.maxHeight = text.scrollHeight + 30 + "px";
    }
    
    closeItem(item, text) {
        item.classList.add('closed');
        item.classList.remove('open');
        text.style.paddingTop = '0px';
        text.style.paddingBottom = '0px';
        text.style.maxHeight = null;
    }
}
