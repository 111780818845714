const menu = function () {
  subMenuImages();
  menuAim();
  navScroll();

  function navScroll() {
    const header = document.querySelector('.main-header');

    document.addEventListener('scroll', () => {
      if (window.pageYOffset > 50) {
        addNavBG();
      } else {
        removeNavBG();
      }
    });

    function addNavBG() {
      header.classList.add('scrolled');
    }

    function removeNavBG() {
      header.classList.remove('scrolled');
    }
  }

  // Loops through all sub-menu images and displays the first only.
  // This means that there is always one image visible on the sub menu.
  function subMenuImages() {
    const menuLevelOneItems = document.querySelectorAll('#primary > .menu-item-has-children');

    menuLevelOneItems.forEach((item) => {
      const images = item.querySelectorAll('.post-thumbnail');
      var count = 0;
      images.forEach((image) => {
        if (count < 1) {
          image.style.opacity = 1;
        }
        count++;
      });
    });
  }

  // Menu aim functionality for meain header nav and sub menus
  function menuAim() {
    var $menu = $('#primary');

    init();

    function init() {
      $('#primary').menuAim({
        activate: activateSubmenu,
        deactivate: deactivateSubmenu,
        exitMenu: function () {
          return true;
        },
        submenuSelector: '.menu-item-has-children',
        submenuDirection: 'below',
      });
    }

    function activateSubmenu(row) {
      var $row = $(row),
        $submenu = $(row).children('.sub-menu'),
        height = $menu.outerHeight(),
        width = $menu.outerWidth();
      // Show the submenu
      $submenu.css({
        display: 'flex',
        opacity: 1,
      });
      // Keep the currently activated row's highlighted look
      $row.find('a').addClass('maintainHover');
    }

    function deactivateSubmenu(row) {
      var $row = $(row),
        $submenu = $(row).children('.sub-menu');

      // Hide the submenu and remove the row's highlighted look
      $submenu.css('display', 'none');
      $submenu.css('opacity', 0);
      $row.find('a').removeClass('maintainHover');
    }

    function exitMenu() {
      $('.level-1 > .sub-menu').css('display', 'none');
      $('.level-1 > .sub-menu').css('opacity', 0);
      $('a.maintainHover').removeClass('maintainHover');
    }
  }
};

export { menu };
