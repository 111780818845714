export default class Form {
  constructor() {
    this.allForms = document.querySelectorAll('.form__wrapper');

    if (this.allForms.length) {
      this.init();
    }
  }

  init() {
    // Loop through all Forms on the page
    this.allForms.forEach((form) => {
      var formInputs = form.querySelectorAll('.wpforms-field-medium');
      formInputs.forEach((input) => {
        input.addEventListener(
          'keydown',
          function () {
            this.showLabel(input);
          }.bind(this)
        );
        input.addEventListener(
          'mouseup',
          function () {
            this.showLabel(input);
          }.bind(this)
        );
        if (input.value && !input.classList.contains('wpcf7-submit')) {
          if (input.value.length > 0) {
            this.showLabel(input);
          }
        }
      });
    });
  }

  showLabel(el) {
    // Search for uncle label element
    let label = document.querySelector(`[for="${el.id}"]`);

    // When label has been found, show it
    label.style.top = '0';
    label.style.opacity = '1';
  }
}
