export default class Modal {
    constructor() {
        this.modal = document.getElementById('modal');
        this.modalClose = document.getElementById('modalClose');

        if(this.modal){
            this.delay = this.modal.dataset.delay * 1000;
            this.visibility = this.modal.dataset.visibility;
            this.init();
            this.events();
        }
    }

    events(){
        this.modalClose.addEventListener('click', ()=>{
            this.closeModal();
        })
        this.modal.addEventListener('click', (e)=>{
            //checks that the modal wrapper was clicked and not the modal
            if(e.target === this.modal){
                this.closeModal();
            }
        })
    }

    init(){
        if(this.visibility === 'show-once'){
            if(localStorage.getItem('modalState') != 'shown'){
                setTimeout(this.showModal, this.delay);
                localStorage.setItem('modalState', 'shown')
            }
        } else {
            setTimeout(this.showModal, this.delay);
            localStorage.setItem('modalState','always-show')
        }
    }

    showModal(){
        this.modal.classList.add('active');
    }

    closeModal(){
        this.modal.classList.remove('active');
    }
}