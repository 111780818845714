"use strict";

export default class NewsSlideShow {
    constructor(){
        this.slides = document.querySelectorAll('.latest-news-slide');
        this.controls = document.querySelectorAll('.control');
        this.slideCounter = [];
        this.currentSlide = 1;

        if(this.slides.length){
            this.init();
            this.events();
        }
    }

    init() {
        let count = 0;
        this.slides.forEach(slide => {
            count ++;
            this.slideCounter.push(count);
            if(count <= 1){
                slide.classList.add('current-slide');
            }
        })

        if(this.slideCounter.length > 1) {
            this.timer = setInterval(this.nextSlide.bind(this), 3000);
        } else {
            this.controls.forEach(control => {
                control.style.display = 'none';
            })
        }

    }

    events(){
        this.controls.forEach(control => {
            control.addEventListener('click', ()=>{
                this.clickHandle(control)
            })
        })
    }

    clickHandle(control){
        clearInterval(this.timer);
        if(control.classList.contains('control__next')){
            this.nextSlide();
        } else if (control.classList.contains('control__previous')){
            this.previousSlide();
        }

        this.timer = setInterval(this.nextSlide.bind(this), 3000);

    }

    nextSlide(){

        if(this.currentSlide <= (this.slideCounter.length)-1){
            this.switchSlide(this.currentSlide, this.currentSlide + 1);
            this.currentSlide++;
        } else {
            this.switchSlide(this.currentSlide, 1);
            this.currentSlide = 1;
        }
    }

    previousSlide(){
        if(this.currentSlide <= 1){
            this.switchSlide(this.currentSlide, this.slideCounter.length);
            this.currentSlide = this.slideCounter.length;
        } else {
            this.switchSlide(this.currentSlide, this.currentSlide - 1);
            this.currentSlide--;
        }
    }

    switchSlide(oldSlideId, newSlideId){
        this.slides.forEach(slide => {
            if(slide.dataset.slidenum == oldSlideId){
                slide.classList.remove('current-slide');
            } else if (slide.dataset.slidenum == newSlideId) {
                slide.classList.add('current-slide');
            }
        })
    }
}